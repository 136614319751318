// Constants
$white: #ffffff;
$black: #000000;

// Branding colors
$brand-primary-color  : #0086cd !default;
$brand-secondary-color: #2d3063 !default;
$brand-success-color  : #009e0f !default;
$brand-warning-color  : #ffb64d !default;
$brand-danger-color   : #fd3258 !default;

// 50 Shades of gray ;)
$light-grey-color     : #eeeeee !default;
$grey-color           : #bbbbbb !default;
$dark-grey-color      : #888888 !default;

// Typo colors
$base-text-color      : #333333 !default;
$secondary-text-color : #999fae !default;
$teriary-text-color   : #bcc1d5 !default;

$base-link-color      : $brand-primary-color !default;
