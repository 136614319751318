@import '_assets/styles/shared.scss';

.filters__fields {
  justify-content: space-between;
}

.filters__actions {
  justify-content: end;
}

.onClickEvent {
  cursor: pointer;
}
