@import '../../_assets/styles/shared';

.order-header {
    font-size: 18px;
}

.orders-back-container {
    text-align: right;
    display: flex;
    justify-content: flex-end;

    .orders-back-btn {
        text-transform: none;
    }
}

.blue-text {
    color: #0086cd;
}

.general-info {
    &__title {
        @include breakpoint-down(pre-md) {
            text-align: center;
        }
    }

    &__panel {
        padding-top: 20px;

        img {
            width: 100%;
        }

        .block-title {
            font-weight: bold;
        }

        .info-block {
            margin-bottom: 10px;
            padding-bottom: 10px;

            .block-content {
                font-size: 14px;
                padding-left: 5px;
            }

            .value {
                font-weight: bold;
            }

            .label {
                max-width: 13%;
            }

            .icon {
                display: inline-block;
                font-size: 18px;
                margin-left: 1rem;
                cursor: pointer;
            }
        }
    }

}

.products-table {
    width: 100%;
    text-align: left;
    font-size: 14px;
    margin-left: -6px;
    margin-right: -6px;
    border-collapse: separate;
    border-spacing: 0 15px;

    @include breakpoint-down(pre-md) {
        width: 100%;
        thead {
            display: none;
        }
    }

    th {
        font-weight: normal;
    }

    tr:last-child td,
    tr:first-child th {
        border-bottom: 1px solid black;
    }

    th {
        padding-bottom: 10px;
    }

    tr:last-child td {
        padding-bottom: 10px;
    }

    p,
    a {
        margin-bottom: 0;
    }

    th:last-child,
    td:last-child {
        text-align: right;
    }
}

.validation-form {
    margin: 1rem;

    .row {
        padding: 10px 0 10px 0;
    }

    .span-input {
        font-weight: bold;
        font-size: 16px;
        display: block;
        border: none;
        background: white;
        color: black;
    }

    .brand {
        margin-left: 15px;
    }

    div.error {
        color: red;
        text-align: left;
    }
}

.validation-order-modal, .order-status-history-modal {
    .modal-content {
        min-height: 150px;
    }

    .modal-body {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 600px;
    }
}

.products-footer {
    margin: 0 7px 20px -6px;
}

.border-bottom {
    border-bottom: 1px solid black;
    &-r {
        @include breakpoint-down(pre-md) {
            border-bottom: 1px solid black;
        }
    }
}

.border-right {
    border-right: 1px solid black;
    &-r {
        border-right: 1px solid black;
        @include breakpoint-down(pre-md) {
            border-right: none;
        }
    }
}

.bold {
    font-weight: bold;
}

.align-vertical-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    margin: auto;
    text-align: center;
}

.history-row {
    margin-bottom: 2rem;

    .time {
        color: gray;
        text-align: right;
    }

    .status {
        margin-left: 2rem;
    }
}

.onClickEvent {
    cursor: pointer;
}

.product-image-td {
    text-align: center;
    img {
        height: 54px;
        width: auto;
    }

    @include breakpoint-down(pre-md) {
        img {
            height: 100%;
            width: auto;
        }
    }
}

.product-image:hover {
    transform: scale(8);
}

.additional-info {
    margin-left: 1%
}

.ml-2 {
    margin-left: 2px;
}

.div-row {
    min-width: 100%;
    display: flex;
    border: 1px transparentize($secondary-text-color, 0.66) solid;
    border-radius: 15px;
    margin: 10px 0;
}
