@import '_assets/styles/shared.scss';

.shopping-cart {
    position: relative !important;
    display: inline-block !important;

    &__count {
        padding: 0;
        position: absolute;
        top: 1.5rem;
        right: 0.5rem;
        background-color: $brand-primary-color;
        color: $white;
        font-weight: normal;
        border-radius: 50%;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 1.2rem;
    }
}

.float-right {
    float: right !important;
}
