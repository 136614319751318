@import '_assets/styles/shared.scss';

.modal {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    display: none;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);

    &.shown {
        display: flex;
        align-items: center;

        .modal-content {
            animation-name: zoomIn;
            animation-duration: .2s;
            animation-fill-mode: both;
        }
    }

    &-content {
        position: relative;
        width: 640px;
        min-height: 200px;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        padding: 0;
        background-color: white;
        border: none;
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.04);

        &.overflow {
            overflow-y: scroll;
        }
    }

    &-header {
        padding: $gutter-size;
        border-bottom: 1px solid $light-grey-color;

        .close {
            position: absolute;
            top: $gutter-size/2;
            right: $gutter-size/2;
            color: rgba($black, 0.3);
            cursor: pointer;
            font-size: 2.5rem;
            transition: color .2s;

            &:hover {
                color: $brand-danger-color;
            }

            &::before {
                content: "\39";
                font-family: "dripicons-v2" !important;
            }
        }
    }

    &-title {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1;
        font-family: $alt-font-family;
        // color: $brand-primary-color;
    }

    &-body {
        padding: $gutter-size*1.5 $gutter-size $gutter-size;
        font-size: 1.4rem;
        line-height: 1.5;
    }
}

@include keyframes(zoomIn) {
    from {
        transform: scale3d(0.2, 0.2, 0.2);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
