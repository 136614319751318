@import '_assets/styles/shared.scss';

.btn {
    display: inline-block;
    position: relative;
    padding: 0.75rem 1.6rem;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.4;
    text-decoration: none !important;
    background-color: $light-grey-color;
    border: 1px solid lighten($field-border-color, 2%);
    transition: all .2s linear;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    z-index: 0;

    &:active,
    &:focus {
        outline: none;
    }

    &:active {
        background-color: darken($light-grey-color, 5%);
        border-color: darken($field-border-color, 8%);
    }

    .label {
        display: inline-block;
        white-space: nowrap;
        font-family: $base-font-family;
        font-weight: 500;
        vertical-align: middle;
        letter-spacing: 0.25px;

        & + .icon {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            margin-left: 1rem;
        }
    }

    &.primary {
        color: white;
        background-color: $brand-primary-color;
        border-color: darken($brand-primary-color, 3%);

        &:active {
            background-color: darken($brand-primary-color, 5%);
            border-color: darken($brand-primary-color, 8%);
        }

        &.disabled,
        &:disabled {
            &:active {
                background-color: $brand-primary-color;
                border-color: darken($brand-primary-color, 3%);
            }
        }

        &.bordered {
            color: $brand-primary-color;
        }
    }

    &.success {
        color: white;
        background-color: $brand-success-color;
        border-color: darken($brand-success-color, 3%);

        &:active {
            background-color: darken($brand-success-color, 5%);
            border-color: darken($brand-success-color, 8%);
        }

        &.disabled,
        &:disabled {
            &:active {
                background-color: $brand-success-color;
                border-color: darken($brand-success-color, 3%);
            }
        }

        &.bordered {
            color: $brand-success-color;
        }
    }

    &.warning {
        color: white;
        background-color: $brand-warning-color;
        border-color: darken($brand-warning-color, 3%);

        &:active {
            background-color: darken($brand-warning-color, 5%);
            border-color: darken($brand-warning-color, 8%);
        }

        &.disabled,
        &:disabled {
            &:active {
                background-color: $brand-warning-color;
                border-color: darken($brand-warning-color, 3%);
            }
        }

        &.bordered {
            color: $brand-warning-color;
        }
    }

    &.danger {
        color: white;
        background-color: $brand-danger-color;
        border-color: darken($brand-danger-color, 3%);

        &:active {
            background-color: darken($brand-danger-color, 5%);
            border-color: darken($brand-danger-color, 8%);
        }

        &.disabled,
        &:disabled {
            &:active {
                background-color: $brand-danger-color;
                border-color: darken($brand-danger-color, 3%);
            }
        }

        &.bordered {
            color: $brand-danger-color;
        }
    }

    .anim {
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;

        &:before {
            position: relative;
            content: '';
            display: block;
            margin-top: 100%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 50%;
        }
    }

    &:hover {
        .anim {
            animation: anim-out .75s;

            &:after {
                animation: anim-out-pseudo .75s;
            }
        }
    }

    // Button styles
    &.loading {
        padding-right: 4rem;

        .spiner {
            display: block;
            width: 2rem;
            height: 2rem;
            font-size: 1.8rem;
            font-weight: 300;
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 2px;
                right: 2px;
                left: 2px;
                bottom: 2px;
                border: 2px solid white;
                border-bottom-color: transparent;
                border-radius: 50%;
                animation: spin 1s infinite linear;
            }
        }
    }

    &.round {
        border-radius: 10rem;
    }

    &.circle {
        border-radius: 50%;
        @include square(4rem);
        padding: 0.25rem !important;
        font-size: 1.8rem;
        line-height: (3.5rem - 1.8rem - 0.25*2);
    }

    &.action {
        border: none;
        background-color: transparent;
        box-shadow: none;

        &:hover {
            background-color: $light-grey-color;
        }

        .icon {
            font-size: inherit;

            &::before {
                line-height: 1.5;
            }

            &.dripicons-dots-3 {
                padding: 0 .45rem;
                font-size: 1.7rem;
                transform: rotate(90deg);
            }
        }

        &.loading {
            padding-right: 0;

            .spiner {
                right: 50%;
                transform: translate(50%, -50%);
            }
        }
    }

    &.link {
        border: none;
        background-color: transparent;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;

        .anim {
            display: none;
        }

        &:hover {
            color: $brand-primary-color;
        }
    }

    &.bordered {
        background-color: transparent;
        text-transform: none;
        border-radius: 3px;

        &:active {
            color: white;
        }
    }

    &.block {
        width: 100%;
    }

    // Disabled
    &.disabled,
    &:disabled {
        opacity: .7;
        cursor: default;
    }
}

// burger
// ==================================================
.burger {
    padding: $burger-padding-y $burger-padding-x;
    display: inline-block;
    cursor: pointer;
    position: relative;

    transition-property: opacity, filter;
    transition-duration: $burger-hover-transition-duration;
    transition-timing-function: $burger-hover-transition-timing-function;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: hidden;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: $burger-hover-opacity;
    }

    .burger-box {
        width: $burger-layer-width;
        height: $burger-layer-height * 3 + $burger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }

    .burger-inner {
        display: block;
        top: 50%;
        margin-top: $burger-layer-height / -2;
        transition: background-color .125s .175s ease-in;

        &,
        &::after,
        &::before {
            width: $burger-layer-width;
            height: $burger-layer-height;
            background-color: $burger-layer-color;
            border-radius: $burger-layer-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: .15s;
            transition-timing-function: ease;
        }

        &::after,
        &::before {
            content: "";
            display: block;
        }

        &::before {
            top: ($burger-layer-spacing + $burger-layer-height) * -1;
            left: 0;
            transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top .05s .125s linear, left .125s .175s ease-in;
        }

        &::after {
            bottom: ($burger-layer-spacing + $burger-layer-height) * -1;
            top: ($burger-layer-height) + ($burger-layer-spacing);
            right: 0;
            transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top .05s .125s linear, right .125s .175s ease-in;
        }
    }

    &.is-active {
        .burger-inner {
            transition-delay: 0s;
            transition-timing-function: ease-out;
            background-color: transparent;

            &::after,
            &::before {
                background-color: $brand-primary-color;
            }

            &::before {
                left: $burger-layer-width * -2;
                top: $burger-layer-width * -2;
                transform: translate3d($burger-layer-width * 2, $burger-layer-width * 2, 0) rotate(45deg);
                transition: left .125s ease-out, top .05s .125s linear, transform .125s .175s cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            &::after {
                right: $burger-layer-width * -2;
                top: $burger-layer-width * -2;
                transform: translate3d($burger-layer-width * -2, $burger-layer-width * 2, 0) rotate(-45deg);
                transition: right .125s ease-out, top .05s .125s linear, transform .125s .175s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
        }
    }
}

@include keyframes(spin) {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@include keyframes(anim-out) {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@include keyframes(anim-out-pseudo) {
    0% {
        background: rgba(#000,0.25);
    }

    100% {
        background: rgba(#000,0);
    }
}
