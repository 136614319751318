@import '../../_assets/styles/shared.scss';

.header {
    height: 8rem;
    background-color: $white;
    display: flex;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    z-index: 1001;

    .logo {
        display: inline-block;
        white-space: nowrap;
        padding: 1.5rem 0;
        margin-right: 3rem;

        img {
            height: 5.4rem;
            width: auto;
        }
    }

    .navbar {
        display: flex;
        width: 90%;
        max-width: $container-width;
        margin: 0 auto;
        align-items: center;
    }

    .menu-dropdown {
        @include breakpoint-down(lg) {

            @include list-unstyle;
            & > li {
                position: relative;
                padding: 0;
                max-height: 6.5rem;
                margin-left: 10px;
            }

            a {
                text-decoration: none;
                font-size: 16pt;
                color: $black;

                &.is-active {
                    color: $base-link-color ;
                }

                & > .shopping-cart__count {
                    top: 0;
                    right: -2.2rem;
                }
            }
        }
    }

    .main-menu {
        @include list-unstyle;
        @include breakpoint-down(pre-md) {
            margin-right: 10px;
        }

        display: flex;
        flex-direction: row;

        & > li {
            position: relative;
            padding: 0;
            max-height: 6.5rem;

            & > .dropdown > a,
            & > a {
                @include breakpoint-down(lg) {
                    padding: 0 1rem;
                }

                display: block;
                padding: 0 3rem;
                font-size: 1.6rem;
                font-family: $alt-font-family;
                font-weight: 500;
                color: $base-text-color;
                position: relative;
                transition: color .1s linear;
                text-decoration: none;

                &:hover {
                    color: $brand-primary-color;
                }

                & > span {
                    display: block;
                    position: relative;
                    padding: 2rem 0;
                }

                &.is-active {
                    color: $brand-primary-color;
                }
            }
        }
    }

    .user-menu {
        margin-left: auto;
    }
}
