@import '_assets/styles/shared.scss';

.dropdown {
    $self: &;
    display: inline-block;
    position: relative;

    &-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        min-width: 18rem;
        margin-top: 1rem;
        padding: .5rem 0;
        text-align: left;
        font-size: 1.4rem;
        list-style: none;
        background-color: white;
        border-radius: .3rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

        &-right {
            right: 0;
            left: auto;
        }
    }

    &-item {
        display: block;
        width: 100%;
        padding: .5rem 2rem;
        color: $base-text-color;
        text-align: inherit;
        //white-space: nowrap;
        background: 0 0;
        border: 0;
        font-weight: normal;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        &:focus,
        &:hover {
            color: #1d1e1f;
            text-decoration: none;
            background-color: $light-grey-color;
        }

        &.active,
        &:active {
            color: #fff;
            text-decoration: none;
            background-color: $brand-primary-color;
        }

        .icon {
            margin-right: .5rem;
            vertical-align: middle;
        }
    }

    &-divider {
        height: 1px;
        margin: .5rem 0;
        overflow: hidden;
        background-color: $light-grey-color;
    }

    // Filters in Dropdown
    .filter-dropdown-trigger {
        padding: 1rem .5rem;
        font-weight: 600;
        color: $secondary-text-color;
        transition: .25s all;

        &:hover {
            color: $base-text-color;
        }

        &.not-empty {
            color: $brand-danger-color !important;
        }

        span {
            display: inline-block;
            margin: 0 .4rem;
        }

        .icon {
            margin: 0 .4rem;
        }
    }

    &--active {
        .filter-dropdown-trigger {
            color: $base-text-color;
        }
    }

    .filter-dropdown-item {
        display: flex;
        flex-flow: row nowrap;
        padding: .5rem 1.5rem;
        align-items: center;
        min-width: 24rem;

        .label {
            width: 35%;
            text-align: left;
            font-weight: 600;

            &:last-child {
                text-align: right;
            }
        }

        .toogler {
            width: 30%;
            text-align: center;

            .toggle-switch .cb {
                margin: 0;
            }
        }
    }
    // END: Filters in Dropdown

    &-trigger {
        cursor: pointer;
    }

    &--active {
        & > #{ $self }-menu {
            display: block;
        }
    }
}
