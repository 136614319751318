.numbered {
  list-style: none;
  counter-reset: item;

  li {
    counter-increment: item;
    margin-bottom: 5px;
  }

  li:before {
    margin-right: 10px;
    content: counter(item);
    background: #0086cd;
    border-radius: 100%;
    color: white;
    width: 1.2em;
    text-align: center;
    display: inline-block;
    line-height: 20px;
    font-size: 1.7rem;
  }
}

.hint {
  font-size: 10px;
  margin-left: 20px;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.ml-5 {
  margin-left: 5rem;
}