@import '_assets/styles/shared.scss';

.page--head {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 100;

    & > .container {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .title {

        h1,
        h2,
        h3 {
            display: inline-block;
            margin: 0;
            margin-bottom: $gutter-size/4;
            line-height: 3.8rem;
            position: relative;
        }

        p {
            font-size: 1.4rem;
            margin-bottom: 0;
        }
    }

    .actions {
        text-align: right;
        margin-left: auto;
    }

    .filters {
        flex: 0 0 100%;
        max-width: 100%;

        &__content {
            border: 1px solid $light-grey-color;
            padding: $gutter-size/2 $gutter-size 0;
            border-radius: 3px;
        }

        &__actions {
            text-align: right;
            padding-top: 10px;

            .btn {
                margin-bottom: 2rem;
            }
        }

        .form-field {
            position: relative;
            padding-top: 10px;
        }

        .form-label {
            font-size: 1.25rem;
            font-weight: 400;
            color: $brand-primary-color;
            display: inline-block;
            background: $white;
            padding: 0 4px;
            position: absolute;
            top: 3px;
            left: 8px;
            z-index: 99;
            line-height: 1;
        }
    }

    @include breakpoint-down(pre-md) {
        margin-bottom: $gutter-size;
        padding: $gutter-size/2 0;
    }
}
