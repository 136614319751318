@import '_assets/styles/shared.scss';

.user-menu {
    @include list-unstyle;
    color: $brand-primary-color;

    &__avatar {
        display: flex;
        @include square(4rem);
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $light-grey-color;
    }

    &__info {
        padding-left: 2rem;

        .company {
            text-transform: uppercase;
            font-weight: 500;
        }

        .user {
            font-size: 85%;
        }
    }

    .dropdown-trigger {
        position: relative;
        padding-right: 2rem;
        display: flex;
        align-items: center;
        line-height: 1.2;

        * {
            -webkit-user-select: none;
            user-select: none;
        }

        .icon {
            line-height: 1rem;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: $dark-grey-color transparent transparent transparent;
            transform-origin: center 1px;
            transition: transform .2s linear;
        }
    }

    .dropdown-item {
        .icon {
            margin-right: 1rem;
            font-size: 1.6rem;
        }
    }

    .dropdown--active {
        .dropdown-trigger {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
}