@import '_assets/styles/shared.scss';

.form-label {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 500;
    color: $base-text-color;
}

.form-field {
    margin-bottom: $gutter-size;

    &.has-error {
        .form-control {
            border-color: lighten($brand-danger-color, 15%);
        }
    }

    .error-message {
        padding-top: .25rem;
        color: $brand-danger-color;
        font-size: 1.2rem;
    }
}

.form-inline {
    .form-label {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
}

.chooser__control--is-focused {
    border-color: $field-border-focus-color !important;
}
