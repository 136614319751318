@import '_assets/styles/shared.scss';

.business-list {
    display: block;
    display: -webkit-flex;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-end;
    text-align: center;

}

.business-block {
    display: block;
    margin-bottom: 30px;
    border: 1px solid $light-grey-color;
    border-radius: $card-border-radius;
    overflow: hidden;
    text-align: left;

    .image {
        display: block;
        position: relative;
        background-color: $light-grey-color;
        width: 100%;
        padding-top: 56.25%;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            font-size: 4rem;
            color: $grey-color;
            line-height: 1;
        }

        img {
            position: absolute;
            @include square(100%);
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

    .description {
        position: relative;
        padding: $gutter-size/2;

        &__title {
            padding-right: 30px;

            a {
                text-decoration: none;
                color: $base-text-color;
                transition: all 0.2s;

                &:hover {
                    color: $brand-primary-color;
                }
            }

            h3 {
                margin: 0 0 $gutter-size/3;
                font-size: 1.8rem;
                font-weight: 500;
                @include text-truncate;
            }
        }

        &__option {
            font-size: 1.4rem;
            display: inline-block;
            margin-right: 20px;
            white-space: nowrap;

            .icon {
                margin-right: 5px;
                vertical-align: middle;
                color: $grey-color;
            }
        }
    }

    .edit-trigger {
        position: absolute;
        top: 6px;
        right: 6px;
        @include square(35px);
        text-align: center;
        padding: 0;

        .icon {
            transform: rotate(-10deg);
        }
    }

    .status-trigger {
        position: absolute;
        top: 6px;
        right: 41px;
        @include square(35px);
        text-align: center;
        padding: 0;
    }
}
