// Global layout sizes
$root-lg-font-size: 62.5% !default;
$root-sm-font-size: 57.5% !default; // 5% less than $base

$gutter-size :      2rem !default;
$grid-columns:      12 !default;

$container-width:   1080px  !default;
$base-font-family: 'Roboto', sans-serif;
$alt-font-family:  'Fira Sans', sans-serif;

// Breakpoints
$breakpoints: (
  xs : 0,
  sm : 576px,
  pre-md: 767px,
  md : 768px,
  lg : 992px,
  xl : 1200px,
  xxl: 1400px 
) !default;

// Burger button
$burger-padding-x                       : 1rem;
$burger-padding-y                       : 1rem;
$burger-layer-width                     : 25px;
$burger-layer-height                    : 2px;
$burger-layer-spacing                   : 7px;
$burger-layer-color                     : #afc6cf;
$burger-layer-border-radius             : 4px;
$burger-hover-opacity                   : 0.7;
$burger-hover-transition-duration       : 0.15s;
$burger-hover-transition-timing-function: linear;

// Cards
$card-border-radius: 4px;
$card-shadow       : 0 0.2rem 0.5rem rgba(0, 0, 0, 0.06);
$card-hover-shadow : 0 0.5rem 1.5rem rgba(0, 0, 0, 0.12);

$card-sm-padding   : $gutter-size;
$card-lg-padding   : $gutter-size*2 $gutter-size;

// Forms
$field-border-color:        #dddddd !default;
$field-border-focus-color:    rgba($brand-primary-color, 0.5) !default;
$field-border-radius:         2px !default;
$field-shadow:                none !default;
