// Keyframes mixin
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content; 
    }
    
	@-moz-keyframes #{$name} {
		@content;
    }
    
	@-ms-keyframes #{$name} {
		@content;
    }
    
	@keyframes #{$name} {
		@content;
	} 
}

// Calculate column size percentage
@function get-col-percent($column-num) {
    @return $column-num / $grid-columns * 100%;
}

// Grid columns
@mixin grid-sizes() {
    // Run the loop for as many columns as speficied
    @for $i from 1 through $grid-columns {
        .col-#{$i} {
            flex-basis: get-col-percent($i);
            max-width:  get-col-percent($i);
        }
    }

    // Run the loop for responsive
    @each $name, $size in $breakpoints {
        @if $name != xs {
            @include breakpoint-up( $name ) {
                @for $i from 1 through $grid-columns {
                    .col-#{$name}-#{$i} {
                        flex-basis: get-col-percent($i);
                        max-width:  get-col-percent($i);
                    }
                }
            }
        }
    }
}

// Media queries
@mixin breakpoint-up( $breakpoint ) {
    @if map-has-key( $breakpoints, $breakpoint ){
        @media ( min-width: #{ map-get( $breakpoints, $breakpoint ) } ) {
            @content;
        }
    }  @else if type_of( $breakpoint ) == number and unit( $breakpoint ) == px or unit( $breakpoint ) == em or unit( $breakpoint ) == rem {
        @media (min-width: $breakpoint ) {
            @content;
        }
    } @else {
        @warn"No value could be retrieved from `#{$breakpoint}`. " + "It is either not defined in the `$breakpoints` map, or it is an invalid number of px, em, or rem.";
    }
}

@mixin breakpoint-down( $breakpoint ) {
    @if map-has-key( $breakpoints, $breakpoint ){
        @media ( max-width: #{ map-get( $breakpoints, $breakpoint ) } ) {
            @content;
        }
    }  @else if type_of( $breakpoint ) == number and unit( $breakpoint ) == px or unit( $breakpoint ) == em or unit( $breakpoint ) == rem {
        @media (max-width: $breakpoint ) {
            @content;
        }
    } @else {
        @warn"No value could be retrieved from `#{$breakpoint}`. " + "It is either not defined in the `$breakpoints` map, or it is an invalid number of px, em, or rem.";
    }
}

// Reverse map keys
@function map-reverse ($map) {
    $result: null;

    @if type-of($map) == "map" {
        $keys: map-keys($map);
        $map-reversed: ();
        
        @for $i from length($keys) through 1 {
            $map-reversed: map-merge(
                $map-reversed,
                (nth($keys, $i): map-get($map, nth($keys, $i)))
            );
        }

        @if type-of($map-reversed) == "map" {
            $result: $map-reversed;
        } @else {
            @warn 'There was an error reversing the order of "#{$map}"';
        }
    } @else {
        @warn '"#{$map}" is not a valid map';
    }

    @return $result;
}

@mixin list-unstyle() {
    padding: 0;
    margin: 0;
    list-style: none;
}

@mixin square($size) {
    width: $size;
    height: $size;
}

@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}