@import '_assets/styles/shared.scss';

.supplier-item {
    cursor: pointer;
    border: 1px solid;
    margin: 2em auto;
    position: relative;
    display: flex;
    padding: 5px 0;
    justify-content: space-between;
    align-items: center;

    .expand-badge {
        position: absolute;
        right: 10px;
        transform: rotate(-90deg);
        //top: 30%;
        margin: auto 0;

        &.expanded {
            transform: rotate(0deg);
        }
    }
}

.full-width {
    width: 100%;
}

.container.no-padding {
    padding: 0;
}

.p-5 {
    padding: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.bordered {
    border-left: 1px transparentize($secondary-text-color, 0.66) solid;
    border-right: 1px transparentize($secondary-text-color, 0.66) solid;
    border-radius: 0 0 15px 15px;
}