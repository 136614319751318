@import '_assets/styles/shared.scss';

.product-container {
    padding: 0 25px;

    .zoom {
        text-decoration: none;
        font-size: 14px;

        .dripicons-zoom-in {
            font-size: 18px;
            vertical-align: sub;
            margin-right: 10px;

        }
    }

    .product-title {
        color: $brand-primary-color;
        font-size: 18px;
        margin-bottom: 0;
    }

    .product-reference, .product-marque, .supplier-reference {
        color: $brand-secondary-color;
        font-size: 14px;
    }

    .product-reference, .supplier-reference {
        margin-right: 50px;
    }

    .product-specifications-title {
        color: $brand-primary-color;
        font-size: 16px;
    }

    .specifications-table {
        width: 100%;
        border-top: 1px solid #9a9a9a;
        border-bottom: 1px solid #9a9a9a;
        border-spacing: 0;
        font-size: 14px;
        color: $brand-secondary-color;

        td {
            padding: 2px 10px;
        }

        .white {
            background-color: white;
        }
    }

}

.product-info-title {
    color: $brand-primary-color;
    margin-bottom: 0;
}

.product-info-text {
    color: $brand-secondary-color;
    font-size: 12px;
    text-align: justify;
}

.suppliers-container {

}

.padding-0-20 {
    padding: 0 20px;
    &-r {
        padding: 0 20px;
        @include breakpoint-down(pre-md) {
            padding: 0 0;
        }
    }
}

.width-100per {
    width: 100%;
}

.text-decoration-none {
    text-decoration: none;
}

.text-center {
    text-align: center;
}

.text-decoration-none {
    text-decoration: none;
}

.mt-2 {
    margin-top: 2rem;
}