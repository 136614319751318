@import '_assets/styles/shared.scss';

.alert {
    display: block;
    position: relative;
    padding: 1rem;
    border: 1px solid $black;
    font-weight: 500;
    margin-bottom: $gutter-size;

    &--success {
        color: $white;
        background-color: $brand-success-color;
        border-color: darken($brand-success-color, 5%);
    }

    &--danger {
        color: $white;
        background-color: $brand-danger-color;
        border-color: darken($brand-danger-color, 5%);
    }

    &--warning {
        color: $white;
        background-color: $brand-warning-color;
        border-color: darken($brand-warning-color, 5%);
    }

    &--info {
        color: $white;
        background-color: $brand-primary-color;
        border-color: darken($brand-primary-color, 5%);
    }
}