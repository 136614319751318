@import '_assets/styles/shared.scss';

#searchCategoriesSelect {
    min-width: 200px;

    .catalog-option__parent {
        display: none;
    }

    .chooser__single-value {
        .catalog-option {
            @include text-truncate;
        }
    }
    
    .chooser__menu {
        z-index: 1001;
        min-width: 250px;
        width: auto;

        .catalog-option {
            display: block;
            line-height: 1.2;
            font-size: 1.4rem;
        
            &__parent {
                display: block;
                font-size: 1.1rem;
                line-height: 1;
                color: $grey-color;
                margin-top: -1px;
            }
        }
    }
}

@include breakpoint-up(md) {
    .search-input-group {
        display: table;
        width: 100%;
        margin-bottom: $gutter-size;
    
        & > * {
            display: table-cell;
            height: 36px;
            vertical-align: middle;
            width: 1%;
        }
    
        .btn {
            border-radius: 0 10rem 10rem 0;
            padding-left: 1.5rem;
            padding-right: 2rem;
            margin-left: -1px;
            white-space: nowrap;
            min-width: 16rem;
        }
    
        .form-field {
            &--text {
                width: 100%;
    
                .form-control {
                    position: relative;
                    border-width: 1px 0 1px 1px;
                    border-radius: 0;
                    margin-left: -1px;
    
                    &:focus {
                        z-index: 10;
                    }
                }
            }
    
            &--select {
                .chooser__control {
                    position: relative;
                    border-radius: 10rem 0 0 10rem;
                    background-color: $light-grey-color;
                    padding-left: 0.5rem;

                    &--is-focused {
                        z-index: 10;
                    }
                }
            }
        }
    }
}

.responsive-mb-1 {
    @include breakpoint-down(pre-md) {
        margin-bottom: 1rem;
    }
}
