@import '_assets/styles/shared.scss';

body.no-scroll #root {
    overflow: visible;
}

.create-business {
    padding-top: 2%;
    overflow: hidden;
}

.d-flex {
    display: flex !important;
}

.justify-content-between {
    justify-content: space-between;
}