@import '_assets/styles/shared.scss';

.button_container {
    padding: 1rem 0 !important;
}

.orderBtn {
    padding-top: 10px;
    float: right;

    &.block {
        float: right;
        width: 150px;
    }
}

.cart {
    &-table {
        border-collapse: collapse;

        &-header {
            font-weight: bold;
            @include breakpoint-down(pre-md) {
                display: none;
            }
        }

        .cart-row {

        }

        .cart-column {
            margin-top: 1rem;
            @include breakpoint-down(pre-md) {
                justify-content: space-between;
            }

            & > .form-field {
                float: left;
                text-transform: uppercase;
                font-weight: 400;
                font-style: normal;
                color: $secondary-text-color;
            }
        }
    }

    &-header {
        display: flex;
        @include breakpoint-down(pre-md) {
            padding: 6px 2px 2px 2px;
            display: unset;
            justify-content: space-between;
        }
    }

    &-link {
        @include breakpoint-down(pre-md) {
            display: inline-block;
        }
    }
}

.remove_product_link {
    color: blue;
    text-decoration: none
}

.quantityInput {
    @include breakpoint-up(md) {
        width: 60px;
        margin-left: auto;
        margin-right: 0;
        display: block;
    }
}

.text-decoration-none {
    text-decoration: none;
}

div.error {
    color: red;
    text-align: right;
    margin-top: 2rem;
}

.MuiTypography-body1 {
    font-size: 1.4rem !important;
    font-weight: bold !important;
}

.MuiFormControl-root {
    width: 100%;
}

.address-country {
    width: 48%;
    float: left;
    margin-left: 4%;
}

.city-postal_code {
    width: 48%;
    float: right;
}

.address-container {
    height: 5rem;
    line-height: 2.5rem;
}

.address-container-suppliers {
    height: 6.5rem;
    line-height: 2rem;
}

.ml-1 {
    margin-left: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

.tarifeo-not-found {
    color: red;
}
