@import '_assets/styles/shared.scss';

.categories-actions {
    @include breakpoint-down(pre-md) {
        display: flex;
        margin: 20px 0 0 0;
        flex-direction: row;
        & > * {
            margin-right: -1rem !important;
        }
    }

    display: unset !important;
    border-bottom: 1px solid $light-grey-color;

    & > * {
        margin-right: 5%;
    }

    a {
        text-decoration: none;
        color: $base-text-color;

        &:hover {
            color: $brand-danger-color;
        }
    }
}

.next_list {
    float: right;
    border-radius: 5px;
}

.supplier-logo {
    height: 80px !important;
    margin-top: unset !important;

    & > img {
        height: 100%;
        cursor: pointer;
    }
}

.logos {
    margin-top: -25px;
    margin-right: 0;
}

@media only screen and (max-width: 1100px) {
    .logos {
        margin-top: -25px;
        margin-left: 0;
    }
}

@include breakpoint-down(pre-md) {
    .logos {
        margin-top: -25px;
        margin-left: 0;
        display: flex;
        flex-direction: row;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
    }
}

.light-box {
    border: 1px transparentize($secondary-text-color, 0.66) solid;
    border-radius: 15px;
    margin: 10px 5px;
}

.secondary {
    background-color: darken($brand-primary-color, 12%);
    color: white;
    & > * {
        color: white;
    }
}

.tertiary {
    background-color: darken($brand-primary-color, 24%);
    color: white;
    & > * {
        color: white;
    }
}

.cursor-pointer {
    cursor: pointer;
}
