@import '_assets/styles/shared.scss';

.footer {
    margin-top: auto;
    background-color: #1f1f1f;
    padding: $gutter-size 0;

    &__content {
        width: 100%;
        max-width: $container-width;
        margin: 0 auto;
        text-align: center;
        font-size: 1.4rem;
    }

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            transition: opacity 0.2s;
            opacity: 0.5;
        }
    }
}