@import '_assets/styles/shared.scss';

.custom-date-range {
    left: 0;
    position: absolute;
    height: 30px;
    top: 0;
    z-index: 1001;

    .field {
        display: flex;
        justify-content: space-between;
        background-color: white;
        line-height: 1.8rem;
    }

    #selection {
        cursor: pointer;
        width: 90%;

        &.gray {
            color: darkgray;
        }
    }

    #close {
        cursor: pointer;
    }

    .rdr-WeekDays {
        display: none;
    }
}
