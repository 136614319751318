@import '_assets/styles/shared.scss';

.page--users {
    .users-table {
        th, td {
            background-color: #eee;
        }
        .actions {
            .icon {
                font-size: 18px;
                cursor: pointer;
            }
            .dripicons-cross {
                margin-left: 15px;
            }
        }
    }
    .users-header {
        justify-content: space-between;
    }
    .actions {
        div {
            display: inline-block;
        }
    }
}
