@import '_assets/styles/shared.scss';

.new_line {
  display: inline;
}

.business_cart:nth-child(even) {
  background: #CCC
}

.business_cart:nth-child(odd) {
  background: #FFF
}

.business_cart_data {
  padding-left: 10px;
}

.business_cart_link {
  color: blue;
  text-decoration: none
}

.business_cart_name {
  color: blue;
  padding-top: 10px;
}

.onClickEvent {
  cursor: pointer;
}

.mt-1 {
  margin-top: 1rem;
}

.submit-button {
  justify-content: flex-end;
}