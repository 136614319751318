@import '_assets/styles/shared.scss';

.custom-date-picker {
    .react-datepicker {
        font-size: 1em;
        border-color: $field-border-color;
    }

    .react-datepicker__header {
        padding-top: .8em;
        background-color: $white;
        border-bottom-color: $field-border-color;
        border-top-left-radius: $field-border-radius;
        border-top-right-radius: $field-border-radius;
    }

    .react-datepicker__triangle {
        .react-datepicker-popper[data-placement^="bottom"] & {
            border-bottom-color: $white;
        }

        .react-datepicker-popper[data-placement^="top"] & {
            border-top-color: $white;
        }
    }

    .react-datepicker__month {
        margin: .4em 1em;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
        width: 1.9em;
        line-height: 1.9em;
        margin: .166em;
    }

    .react-datepicker__current-month {
        font-size: 1em;
    }

    .react-datepicker__navigation {
        top: 1em;
        line-height: 1.7em;
        border: .45em solid transparent;
    }

    .react-datepicker__navigation--previous {
        border-right-color: #ccc;
        left: 1em;
    }

    .react-datepicker__navigation--next {
        border-left-color: #ccc;
        right: 1em;
    }

    .react-datepicker__day--today {
        color: $brand-primary-color;
    }

    .react-datepicker__input-container {
        width: 100%;

        input {
            width: 100%;
            height: 3.6rem;
            font-size: 1.6rem;
            padding: .8rem 1.2rem;
            border: 1px solid $field-border-color;
            border-radius: $field-border-radius;
            box-shadow: $field-shadow;
            transition: border-color .15s ease-in-out;

            &:focus {
                outline: none;
                border-color: $field-border-focus-color;
            }
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__day--selected {
        background-color: $brand-primary-color;
        border-radius: $field-border-radius;
        font-weight: 500;
    }

    &.has-error input {
        border: 2px solid #fe7e96 !important;
        outline: none;
    }
}
