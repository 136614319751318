@import '_assets/styles/shared.scss';

.page--auth {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-image: url('../_assets/images/heroImage-2.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.auth-card {
    display: flex;
    padding: $gutter-size;
    background: $white;
    width: 60rem;

    @include breakpoint-down(pre-md) {
        display: inline-block;
        height: 100%;
        text-align: center;

        &__logo,
        &__content {
            display: block;
            text-align: center;
        }

        &__content {
            width: 100% !important;
        }

        &__logo {
            width: 40% !important;
            height: 30%;
            margin: 0 auto !important;
            padding: 0 2rem !important;
            display: flex;
            justify-content: center;
            position: relative;

            img {
                height: 100%;
                width: auto !important;
            }
        }
        & .logo {
            height: 100%;

        }
    }

    &__logo,
    &__content {
        display: flex;
        align-items: flex-start;
        padding: 0 $gutter-size;
    }

    &__logo {
        width: 30%;
        padding-top: 7%;
        margin: auto;

        img {
            width: 100%;
        }
    }

    &__content {
        width: 70%;

        & > div {
            width: 100%;
        }

        h3 {
            margin-top: 0;
        }
    }

    .btn[type="submit"] {
        min-width: 17rem;
    }
}
