.color-20527a {
  color: #20527a;
}

.dropdown-menu-inline{
  top: 0;
  left: 100%;
}

.width-100per{
  width: 100%;
}
.dropdown{
  .dripicons-chevron-right{
    right: 0;
    position: absolute;
    margin-right: 5px;
    line-height: 30px;
    color: #20527a;
  }

  .dropdown-item{
    .category-title{
      margin-right: 15px;
      color: #20527a;
    }

    .subcategory-title{
      text-decoration: none;
    }

  }
}

.mr-10{
  margin-right: 10px;
}

.va-middle{
  vertical-align: middle;
}

.m-7-0{
  margin: 7px 0;
}
