@import '_assets/styles/shared.scss';
@import-normalize;
@import '~dripicons/webfont/webfont.css';
@import url( 'https://fonts.googleapis.com/css?family=Roboto:400,400i,500|Fira+Sans:400,500,700&display=swap');

// Base
// ----------------------------------

html {
    font-size: $root-lg-font-size; // we'll use rem
}

body,
html {
    margin: 0;
    height: 100%;
}

body {
    font-family: $base-font-family;
    font-size: 1.6rem;
    line-height: 1.5;
    background-color: $white;
    color: $base-text-color;

    &.no-scroll {
        #root {
            height: 100%;
            overflow: hidden;
        }
    }
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

// Typography
// ----------------------------------

a {
    color: $brand-primary-color;
    cursor: pointer;

    .link {
        position: relative;
        display: inline-block;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 2px;
            left: 0;
            background-color: $brand-primary-color;
            opacity: 0;
            transition: opacity .2s linear;
        }
    }

    &.is-active,
    &:hover {
        .link:after {
            opacity: 1;
        }
    }
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

label {
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $alt-font-family;
    font-weight: 500;
}

// Layout
// ----------------------------------

#root {
    position: relative;
    width: 100%;
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 100%;
}

.main {
    .container {
        width: 100%;
        max-width: $container-width;
        margin: 0 auto;
        padding: $gutter-size 0;
    }
}

// Basic Grid
// ----------------------------------

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter-size/2;
    margin-right: -$gutter-size/2;

    .col,
    [class*="col-"],
    [class^="col-"] {
        position: relative;
        padding-right: $gutter-size/2;
        padding-left: $gutter-size/2;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    @include grid-sizes();
}

// Tables
// ----------------------------------
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    text-align: left;
    font-size: 1.4rem;

    thead {
        th {
            padding: 1.5rem;
            background-color: #f6f6f6;
            font-weight: 500;
            font-family: $alt-font-family;
            vertical-align: middle;
            color: $base-text-color;
            user-select: none;
            -webkit-user-select: none;
        }
    }

    tbody {
        td {
            padding: 0.7rem 1.4rem;
            background-color: $white;
            border-top: 1px solid $light-grey-color;
            vertical-align: middle;
            transition: background-color .2s linear;
        }

        tr:hover td {
            background-color: rgba($brand-primary-color, 0.05);
        }

        strong {
            font-weight: 500;
        }
    }

    .id {
        width: 1%;
        text-align: center;
        font-size: 1.2rem;
        color: $secondary-text-color;
    }

    .actions {
        width: 1%;
        text-align: right;

        .btn.action {
            @include square(3.2rem);

            &:hover,
            &:focus {
                background-color: $brand-primary-color;
                color: $white;
            }
        }
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .left {
        text-align: left;
    }

    .main {
        color: $brand-primary-color;
    }

    .no-data {
        text-align: center;
    }
}

// Tables Responsive
// ----------------------------------
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include breakpoint-down (md) {
        margin: 6px 2px 2px 2px;
        thead {
            display: none;
        }

        tr {
            display: block;
            padding: 1rem;
            background-color: $white;
            border-radius: 3px;
            margin-bottom: 2rem;
            position: relative;
            transition: border-color .2s linear;
            border: 1px rgba(172, 172, 172, 0.33) solid;

            &:hover {
                td {
                    background-color: $white !important;
                }
            }

            td {
                display: block;
                width: 100% !important;
                max-width: 100% !important;
                text-align: right !important;
                padding: 1rem;
                border-top-style: dashed;
                font-size: 1.3rem !important;

                &:before {
                    content: attr(data-label);
                    float: left;
                    text-transform: uppercase;
                    font-weight: 400;
                    font-style: normal;
                    color: $secondary-text-color;
                }

                &:first-child {
                    border: 0;
                    text-align: left !important;
                    padding-right: 3rem;

                    &:before {
                        display: none;
                    }
                }

                &.main {
                    text-align: left !important;
                    padding-right: 3rem;

                    &:before {
                        display: none;
                    }
                }

                &.actions {
                    position: absolute;
                    width: 3rem !important;
                    height: 3rem;
                    padding: 0;
                    top: 1.2rem;
                    right: 1rem;
                    border: none;

                    .icon {
                        color: $base-text-color;

                        &.dripicons-dots-3 {
                            font-size: 2rem;
                        }
                    }
                }

                &.no-data {
                    text-align: center !important;
                }

                &.hide {
                    display: none;
                }
            }
        }
    }
}

// Helpers
// ----------------------------------

// Text alignment

.text-justify {
    text-align: justify !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    @include text-truncate;
}

// Text transformation

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

// Common Page's Styles
// ----------------------------------

.page {
    position: relative;

    &-title {
        color: $brand-primary-color;
        font-weight: 400;
        margin: 0 0 $gutter-size;
    }
}

.bg-white {
    background-color: #fff;
}

// Filters
//------------------------------------

.filters-trigger {
    margin-bottom: 20px;
}

.filter-dropdown {
    label {
        display: block;
    }

    div.dropdown {
        width: 100%;
    }

    .dropdown-menu {
        width: 100%;

        div {
            padding: 5px 0 0 5px;
            cursor: pointer;
        }
    }

}
