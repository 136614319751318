@import '_assets/styles/shared.scss';

.categories-actions {
    display: flex;
    border-bottom: 1px solid $light-grey-color;

    & > * {
        margin-right: 5%;
    }

    a {
        text-decoration: none;
        color: $base-text-color;

        &:hover {
            color: $brand-danger-color;
        }
    }
}

.next_list {
    float: right;
    border-radius: 5px;
}

.supplier-logo {
    height: 40px;
    margin-top: 20px;
}

.logos {
    margin-top: -25px;
    margin-right: 0;
}

@media only screen and (max-width: 1100px) {
    .logos {
        margin-top: -25px;
        margin-left: 0;
    }
}
