@import 'shared';

.container {
    @include breakpoint-down(lg) {
        padding: 0 20px !important;
    }
}

.title {
    @include breakpoint-down(lg) {
        text-align: left;
        max-width: 75%;
    }
}

.z-superior {
    &:not(.form-field) {
        z-index: 1111;
    }

    & > label {
        z-index: 1112 !important;
    }
}

.mt-ia-2 {
    @include breakpoint-down(pre-md) {
        margin-top: 2rem;
    }
}

.responsive {
    &-width {
        @include breakpoint-down(pre-md) {
            min-width: 100%;
        }
    }

    &-container {
        @include breakpoint-down(lg) {
            padding: 2rem 20px !important;
        }
    }

    &-thead {
        @include breakpoint-down(pre-md) {
            display: none;
        }
    }
}

.m-10-0 {
    margin: 10px 0;
}
