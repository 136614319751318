@import '_assets/styles/shared.scss';

.alert-item {
    color: white;
    position: relative;
    padding:12px 15px 5px 5px;
    opacity: 0.75;
    z-index: 999;
    transition: top 0.5s ease;
    border: 2px solid gray;
    border-radius: 3px;
    .icon {
        display: inline-block !important;
        position: absolute;
        top: -7px;
        right: -6px;
        color: #0086cd;
        background: white;
        padding: 2px 2px 0 2px;
        border: 1px solid #9a9a9a;
        line-height: 12px;
        border-radius: 2px;

        &:hover {
            color: red;
        }
    }
}

.alert-container{
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 1002;
    transition: top 0.5s ease;

    @include breakpoint-down(pre-md) {
        top: 20%;
        margin: 0 1px;
        justify-content: center;
    }
}

