@import '_assets/styles/shared.scss';

.catalogue-container {
    padding: 0 25px;

    .subcategory-item {
        label {
            font-weight: normal;
            color: $brand-secondary-color;
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .subcategories-container {
        border-right: 1px solid #80808038;
        @include breakpoint-down(pre-md) {
            border: none;
            min-width: 100%;
            .title {
                font-size: 16pt;
            }
        }

        padding-right: 15px;

        .title {
            color: $brand-primary-color;
        }
    }

    .products-container {
        padding-left: 15px;
        @include breakpoint-down(pre-md) {
            padding-left: 0;
            .title {
                font-size: 16pt;
            }
        }

        .title {
            color: $brand-primary-color;
        }

        .product-link {
            color: $brand-secondary-color;
            font-size: 15px;
        }
    }

}

.text-decoration-none {
    text-decoration: none;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted silver;
}

.tooltip .tooltiptext {
    @include breakpoint-down(pre-md) {
        display: none;
    }

    visibility: hidden;
    width: max-content;
    background-color: silver;
    color: #20527a;
    text-align: center;
    border-radius: 6px;
    padding: 1px 0;

    /* Position the tooltip */
    position: absolute;
    margin-left: 10%;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}