@import '_assets/styles/shared.scss';

.checkbox {
    display: inline-block;

    label {
        display: inline-block;
        margin: 0;
        font-weight: normal;
        user-select: none;
        cursor: pointer;
    }
    
    .cb {
        position: relative;
        text-align: center;
        display: inline-block;
        border: 2px solid $field-border-color;
        border-radius: 0;
        width: 18px;
        height: 18px;
        margin-right: 1rem;
        vertical-align: middle;
        color: $brand-primary-color;
        
        .icon {
            visibility: hidden;
            line-height: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);
        }
    }

    input {
        display: none;

        &:checked + .cb {
            border-color: darken($brand-primary-color, 3%);
            background-color: $brand-primary-color;
            color: white;

            .icon {
                visibility: visible !important;
            }
        }
    }

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    // Colors
    &.success {
        .cb {
            color: $brand-success-color;
        }
    
        input:checked + .cb {
            border-color: darken($brand-success-color, 3%);
            background-color: $brand-success-color;
        }
    }

    &.danger {
        .cb {
            color: $brand-danger-color;
        }
    
        input:checked + .cb {
            border-color: darken($brand-danger-color, 3%);
            background-color: $brand-danger-color;
        }
    }

    &.warning {
        .cb {
            color: $brand-warning-color;
        }
    
        input:checked + .cb {
            border-color: darken($brand-warning-color, 3%);
            background-color: $brand-warning-color;
        }
    }

    &.disabled {
        label {
            cursor: default;
            opacity: 0.95;
        }
    }
}