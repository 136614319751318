@import '_assets/styles/shared.scss';

.page--user{
  .card {
    margin: auto;

    .card-body {
      background-color: white;
      border-radius: $card-border-radius;
      box-shadow: $card-shadow;
      border: 1px solid white;
      position: relative;

      .area-title {
        margin: 2rem 0 3rem 0;
        position: relative;

        &:nth-child(1) {
          margin-top: 0;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -1px;
          width: 100%;
          height: 1px;
          background-color: #ddd;
        }

        span {
          display: inline-block;
          background-color: white;
          padding-right: 1rem;
          position: relative;
          color: #999fae;
        }
      }

      form {
        display: block;
        padding: 1rem;
      }

      .field {
        margin-bottom: 2rem;
      }
    }

    &.card-sm  {
      .card-body {
        padding: $card-sm-padding;
      }
    }

    &.card-lg {
      .card-body {
        padding: $card-lg-padding;
      }
    }

    .card-actions {
      position: absolute;
      top: $gutter-size/4;
      right: $gutter-size/4;
      z-index: 2;
    }
  }

  .user-form .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}
