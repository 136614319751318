@import '_assets/styles/shared.scss';

.file-input {
    display: flex;
    align-items: center;

    &__control {
        position: relative;
        padding-right: $gutter-size;

        input {
            position: absolute;
            opacity: 0;
            @include square(0px);
            font-size: 0;
        }
    }

    &__description {
        display: block;
        
        p {
            margin: 0;
            font-style: italic;
            font-size: 1.3rem;
        }
    }
}