@import '_assets/styles/shared.scss';

.categories {
    padding-top: 0 !important;

    &__title {
        margin-top: 0;
        margin-bottom: $gutter-size * 1.5;
        font-size: 2rem;
    }

    &__container {
        @include clearfix;
        margin-left: -($gutter-size/2);
        margin-right: -($gutter-size/2);

        @include breakpoint-up(md) {
            column-count: 2;
        }

        @include breakpoint-up(lg) {
            column-count: 3;
        }

        ul {
            display: inline;
            @include clearfix;
            padding: 0;
            margin: 0 0 $gutter-size * 1.5;
            list-style: none;
            break-inside: avoid;
            padding-left: $gutter-size/2;
            padding-right: $gutter-size/2;

            li {
                display: block;
            }
        }

        .category {
            display: inherit;
            &__title {
                color: $brand-primary-color;
                margin-top: 0;
                margin-bottom: $gutter-size/4;
                font-family: $alt-font-family;
                font-weight: 500;
                line-height: 1.2;
                text-transform: lowercase;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            a {
                display: inline-block;
                clear: both;
                text-decoration: none;
                color: $base-text-color;
                line-height: 1.8;
                text-transform: lowercase;

                &::first-letter {
                    text-transform: capitalize;
                }

                &:hover {
                    text-decoration: underline;
                    color: $brand-primary-color;
                }
            }
        }
    }
}
