@import '_assets/styles/shared.scss';

.suppliers-container {
  .title {
    color: $brand-primary-color;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .supplier-container {
    border: 1px solid;
    padding: 0;

    .quantity-label {
      vertical-align: sub;
    }

    .mt-10 {
      margin-top: 10px
    }

    .mb-20 {
      margin-bottom: 20px
    }

    .supplier-name {
      font-weight: 500;
      font-size: 17px;
      text-align: center;
      background: #dad7d7;
      padding: 5px 0;
      border-bottom: 1px solid;
    }

    .price-details-container {
      background-color: #fff;
      padding: 7px 10px;
      color: $brand-primary-color;
      font-size: 15px;
      width: 90%;
      margin-right: 5%;
      margin-left: 5%;



      .money-symbol {
        margin-left: 10px;
        background-color: #2b4d80;
        color: #fff;
        padding: 1px 5px;
        border-radius: 10px;
        font-size: 15px;
      }

      .price {
        font-size: 15px;
      }

      .price-black {
        font-size: 15px;
        color: black;
      }

      .days {
        float: right;

        .dripicons-clock {
          margin-left: 10px;
          vertical-align: text-top;
        }
      }

    }
  }
}

.justify-content-center {
  justify-content: center;
}
