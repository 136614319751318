@import '_assets/styles/shared.scss';

.form-control {
    width: 100%;
    height: 3.6rem;
    font-size: 1.6rem;
    padding: 0.8rem 1.2rem;
    border: 1px solid $field-border-color;
    border-radius: $field-border-radius;
    box-shadow: $field-shadow;
    transition: border-color 0.15s ease-in-out;

    &:hover {
        border-color: $field-border-color;
    }

    &:focus,
    .focus {
        outline: none;
        border-color: $field-border-focus-color;
    }

    &::-webkit-input-placeholder {
        color: $grey-color;
    }

    &::-moz-placeholder {
        color: $grey-color;
    }

    &:-ms-input-placeholder {
        color: $grey-color;
    }

    &:-moz-placeholder {
        color: $grey-color;
    }
}